// Api
import { getCompanyById } from '../../../shared/api/company.api';

// Actions
import * as CommonAction from './invest-details-common.actions';
import * as CompanyAction from '../../../shared/actions/actions/company.actions';
import { setAutocompleteInseeSirenSuccess } from '../../../shared/actions/actions/company.actions';
import { clearMessagesEveryXTime } from '../../../shared/actions/actions/common.actions';
// Utils
import { FAKE_IBAN_PATTERN } from '../../../shared/utils/CatalizrConstants';
import { create, bond, increase } from '../../../shared/utils/OperationUtils';
import { dispatchChangeToInvestDetailsForm } from '../../../shared/utils/CatalizrFormUtils';
// Constants
import * as InvestDetailsConst from './invest-details.const';

import store from '../../../store';
import { postErrorTemplate } from '../../../shared/api/company.api';
import { buildInvestorCompanyDataToDispatch } from './invest-details-investor.actions';

import { isEmpty } from 'lodash';

// --------------------------------------------------------------------------------------------------------
// DATA INITIALIZATION
// --------------------------------------------------------------------------------------------------------

/**
 * Initializes the company data into redux and the 'invest-details' redux form.
 * @param {object} investment the investment data containing the company to initialize
 * @param {function} dispatch the redux dispatch function
 * @void (dispatch the result data to redux & redux-form)
 */
export const initInvestDetailsWithCompanyData = (investment, dispatch) => {
  const companyData = investment ? investment.company : null;
  if (companyData) {
    dispatchCompanyData(companyData, dispatch);
  }
};

export const initInvestDetailsWithCompanyKindProviderData = (investment, dispatch) => {
  const companyKindProviderData = investment ? investment.company_a : null;
  const fullCompanyKindProviderData = {
    ...companyKindProviderData,
    renounce_to_compensation: investment.renounce_to_compensation,
    compensation: investment && investment.compensation ? investment.compensation.toString() : '0',
    contributed_part_amount: investment.contributed_part_amount,
  };
  if (fullCompanyKindProviderData) {
    dispatchCompanyKindProviderData(fullCompanyKindProviderData, dispatch);
  }
};

/**
 * Dispatch the company data of the given companyId to the 'invest-details' redux-form.
 * @param {string} companyId the mongo id of the company to process
 * @void (dispatch the result data to redux-form)
 */
export const setCompanyDetailsToInvestDetails = companyId => dispatch => {
  getCompanyById(companyId).then(resp => {
    const companyData = resp && resp.data ? resp.data : null;
    if (companyData) {
      dispatchCompanyData(buildCompanyDataToDispatch(companyData), dispatch);
    }
  });
};

/**
 * Dispatch the company data from SIRENE API (INSEE)
 * @param {object} companyData companyDatas retrieve from SIRENE API (INSEE)
 * @param {boolean} datasFromInsee ALL company datas came from INSEE
 * @void (dispatch the result data to redux-form)
 */
export const setCompanyInseeDataToInvestDetails =
  (companyData, datasFromInsee, reduxObjectName) => dispatch => {
    const isCeasedCompany = companyData ? companyData.is_ceased : null;
    if (companyData) {
      // Set data_from_insee to true to disabled company fields filled by SIRENE API
      dispatch(setAutocompleteInseeSirenSuccess(datasFromInsee));
      companyData.data_from_insee = datasFromInsee;

      switch (reduxObjectName) {
        case 'company':
          dispatch(CompanyAction.setCeasedCompanyAlert(isCeasedCompany));
          dispatchCompanyData(buildCompanyDataToDispatch(companyData), dispatch);
          break;
        case 'companyKindProvider':
          // authorize company to be ceased
          dispatchCompanyKindProviderData(companyData, dispatch);
          break;
        case 'seller':
          // authorize company to be ceased
          dispatchSellerData(buildCompanyDataToDispatch(companyData), dispatch);
          break;
        case 'investors[0]':
          dispatch(CompanyAction.setCeasedCompanyAlert(isCeasedCompany));
          dispatchInvestorData(
            reduxObjectName,
            buildInvestorCompanyDataToDispatch(companyData),
            dispatch,
          );
          break;
        default:
          break;
      }
    } else return;
  };

/**
 * Reset company siren in form (ex : if we change registered country, we reset company siren)
 */
export const resetCurrentCompanySiren =
  (reduxObjectName = 'company') =>
  dispatch => {
    dispatchChangeToInvestDetailsForm(`${reduxObjectName}.siren`, '', dispatch);
  };

/**
 * Reset reduxObjectName (seller, investor) in form (ex : if we change civility, we reset current seller siren, company name, Address, Zip, City ...)
 */
export const resetCurrentObjectOnInvalidSiren = reduxObjectName => dispatch => {
  const fieldsToReset = [
    'company_name',
    'siren',
    'address',
    'zip',
    'city',
    'legal_form',
    'name',
    'surname',
  ];
  fieldsToReset?.forEach(field => {
    const resetValue = field === 'legal_form' ? 'SA' : '';
    dispatchChangeToInvestDetailsForm(`${reduxObjectName}.${field}`, resetValue, dispatch);
  });
};

// --------------------------------------------------------------------------------------------------------
// DATA CREATION & UPDATE
// --------------------------------------------------------------------------------------------------------

/**
 * Creates the object containing the company data to send to the validate function.
 * @param {*} dataFromForm the data contained in the form
 * @param {string} objectName object to get company info
 */
export const buildCompanyData = (dataFromForm, objectName) => {
  const companyDataFromForm = dataFromForm[objectName];
  const financialBrokerDataFromForm = dataFromForm.financialBroker;

  let inProgressValue =
    create() && (!companyDataFromForm.siren || companyDataFromForm.siren === '');

  const companyData = {
    siren: companyDataFromForm.siren,
    legal_form: companyDataFromForm.legal_form,
    name: companyDataFromForm.name,
    type: companyDataFromForm.type ? companyDataFromForm.type : 'ORDINARY',
    address: companyDataFromForm.address,
    zip: companyDataFromForm.zip,
    cfi_code: companyDataFromForm.cfi_code,
    city: companyDataFromForm.city,
    country: companyDataFromForm.country,
    registered_country: companyDataFromForm.registered_country,
    // no user for bond operation => set an empty object
    user: companyDataFromForm.user ? companyDataFromForm.user : {},
    bank_name: isEmpty(companyDataFromForm.bank_name) ? '' : companyDataFromForm.bank_name,
    in_progress: inProgressValue,
    data_from_insee: companyDataFromForm.data_from_insee,
    eligibility: companyDataFromForm.eligibility,
    eligibility_comment: companyDataFromForm.eligibility_comment,
  };

  // send data only if provided
  if (companyDataFromForm.iban && companyDataFromForm.iban !== '') {
    companyData.iban = companyDataFromForm.iban;
  }

  // send the company mongo id for a create operation when the user has selected a company in suggestion
  if (create() && companyDataFromForm.id) {
    companyData.id = companyDataFromForm.id;
  }

  // specific case for bond - set the user email coming from the financial broker
  if (bond()) {
    companyData.user.email = financialBrokerDataFromForm.user.email;
  }

  const optionalsDatas = ['boss'];
  optionalsDatas.forEach(currentProp => {
    if (companyDataFromForm[currentProp]) {
      companyData[currentProp] = companyDataFromForm[currentProp];
      if (
        Object.prototype.hasOwnProperty.call(companyData[currentProp], 'phone') &&
        companyDataFromForm.user &&
        companyData[currentProp].phone !== companyDataFromForm.user.phone
      ) {
        // We update boss phone with company user phone updated in form if needed
        companyData[currentProp].phone = companyDataFromForm.user.phone;
      }
      if (
        Object.prototype.hasOwnProperty.call(companyData[currentProp], 'email') &&
        companyDataFromForm.user &&
        companyData[currentProp].email !== companyDataFromForm.user.email
      ) {
        // We update boss email with company user email updated in form if needed
        companyData[currentProp].email = companyDataFromForm.user.email;
      }
    }
  });
  return companyData;
};

const startNewErrorTemplatePending = () => ({
  type: InvestDetailsConst.START_NEW_ERROR_TEMPLATE_PENDING,
});

const startNewErrorTemplateFulfilled = errorTemplate => ({
  type: InvestDetailsConst.START_NEW_ERROR_TEMPLATE_FULFILLED,
  errorTemplate,
});

const startNewErrorTemplateRejected = () => ({
  type: InvestDetailsConst.START_NEW_ERROR_TEMPLATE_REJECTED,
});

export const startNewErrorTemplate = (formValues, investment) => async dispatch => {
  const { ...rest } = formValues;
  const newErrorTemplate = {
    investment_id: investment.id,
    ...rest,
  };

  dispatch(startNewErrorTemplatePending());
  try {
    const postNewErrorTemplate = await postErrorTemplate(newErrorTemplate);
    dispatch(startNewErrorTemplateFulfilled(postNewErrorTemplate));
  } catch (error) {
    dispatch(startNewErrorTemplateRejected());
    throw error;
  } finally {
    dispatch(clearMessagesEveryXTime(3000));
  }
};

// -----------------------------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------------------------

const dispatchCompanyData = (companyData, dispatch) => {
  const companyDataToDispatch = buildCompanyDataToDispatch(companyData);
  // dispatch the company data to the redux form
  dispatchChangeToInvestDetailsForm('company', companyDataToDispatch, dispatch);
  // dispatch action to redux except if bank is editing company data via dashboard : company data will be dispatch into redux only if bank clicks on validate button
  const bankIsEditingCompanyDataViaDashboardForm = store.getState().dashboard.displayDashboardForm;
  if (!bankIsEditingCompanyDataViaDashboardForm) {
    dispatch(CommonAction.setCompanyData(companyDataToDispatch));
  }
  // init the company legal forms
  dispatch(CommonAction.initLegalFormsAndBossStatuses());
};

const dispatchSellerData = (sellerData, dispatch) => {
  const sellerDataToDispatch = { ...sellerData, title: 'Personne morale' };

  // dispatch the company data to the redux form
  dispatchChangeToInvestDetailsForm('seller', sellerDataToDispatch, dispatch);
};

const dispatchInvestorData = (reduxObjectName, investorData, dispatch) => {
  const investorDataToDispatch = { ...investorData, title: 'Personne morale' };

  // dispatch the company data to the redux form
  dispatchChangeToInvestDetailsForm(reduxObjectName, investorDataToDispatch, dispatch);
};

const dispatchCompanyKindProviderData = (companyKindProviderData, dispatch) => {
  dispatchChangeToInvestDetailsForm('companyKindProvider', companyKindProviderData, dispatch);
  // dispatch action to redux except if bank is editing company data via dashboard : company data will be dispatch into redux only if bank clicks on validate button
  const bankIsEditingCompanyDataViaDashboardForm = store.getState().dashboard.displayDashboardForm;
  if (!bankIsEditingCompanyDataViaDashboardForm) {
    dispatch(CompanyAction.setCompanyKindProviderData(companyKindProviderData));
  }
};

const buildCompanyDataToDispatch = company => {
  const companyData = {
    sql_id: company.sql_id,
    id: company.id,
    siren: company.siren,
    name: company.name,
    type: company.type,
    boss: company.boss,
    user: company.user,
    address: company.address,
    country: company.country,
    registered_country: company.registered_country,
    city: company.city,
    cfi_code: company.cfi_code,
    zip: company.zip,
    legal_form: company.legal_form,
    bank_name: company.bank_name,
    fundraising_default: company.fundraising_default,
    in_progress: company.in_progress,
    data_from_insee: company.data_from_insee,
    eligibility: company.eligibility,
    is_ceased: company.is_ceased,
    eligibility_comment: company.eligibility_comment,
  };

  // dispatch the company iban only if not a fake generated (fake company iban are generated for repurchase operations, only for new company)
  if (typeof company.iban === 'string' && !company.iban.startsWith(FAKE_IBAN_PATTERN)) {
    companyData.iban = company.iban;
  }

  // if the company changes in the form (click on a company suggestion), we empty the iban field
  // we empty IBAN only if it is required in form (increase and create only for now)
  if ((increase() || create()) && !company.user) {
    companyData.iban = '';
  }

  return companyData;
};
